<template>
  <div class="single-list-item" @click="click">
    <div class="single-list-top">
      <img :src="mediaSource.thumb" alt="" />
    </div>
    <div class="single-list-bottom">
      <div style="display: flex; justify-content: space-between;">
        <VueHeadline level="4" weightLevel="3" class="single-item-text"> {{ text }}</VueHeadline>
        <img v-if="hasRosette" :src="rosetteImage" style="height: 30px !important;" />
      </div>
      <BrandButton
        v-if="visibleQuiz && !isSurveySuccessful"
        @click="openQuizPopup"
        :size="sizes.xSmall"
        :contentAlignment="constants.flexAlignment.center"
        class="content-action-btn"
        >TESTİ ÇÖZ</BrandButton
      >
      <div v-if="showPercentage" class="progressbar-wrapper">
        <div class="progressbar">
          <div class="completed-part" :style="{ width: `${percentage * 2.25}px` }"></div>
        </div>
        <VueText sizeLevel="4" weightLevel="2"> % {{ `${percentage.toFixed()}` }} </VueText>
      </div>
    </div>
    <RosettesQuizPopup
      v-if="showRosetteQuizPopup"
      @closeQuizPopup="closeQuizPopup"
      @surveyTakenStatus="handleSurveyTakenStatus"
      :rosetteType="userGuideRosetteType"
      :rosetteInput="mediaSource.rosetteInput"
      :key="quizKey"
    />
  </div>
</template>

<script>
import VueHeadline from '@/components/shared/VueHeadline/VueHeadline.vue';
import VueText from '@/components/shared/VueText/VueText.vue';
import COMPONENT_CONSTANTS from '@/constants/component.constants.js';
import { FLEX_JUSTIFIES } from '@/constants/flex.constants.js';
import BrandButton from '@/components/brand/BrandButton/BrandButton.vue';
import RosettesQuizPopup from '@/views/pages/secure/settings/RosettesQuizPopup.vue';
import {
  validQuizStatusTypes,
  SurveyTakenStatusType,
} from '@/modules/rosettes/constants/rosetteConstants.js';

export default {
  name: 'BrandMediaListItem',
  components: { VueHeadline, VueText, BrandButton, RosettesQuizPopup },
  props: {
    text: {
      type: String,
      default: '',
    },
    percentage: {
      type: Number,
      default: 0,
    },
    mediaSource: {
      type: Object,
    },
    showPercentage: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    sizes() {
      return COMPONENT_CONSTANTS.COMPONENT_SIZES;
    },
    constants() {
      return {
        flexAlignment: FLEX_JUSTIFIES,
      };
    },
    visibleQuiz() {
      return (
        this.mediaSource?.rosetteInput?.isRosetteTask &&
        validQuizStatusTypes.includes(this.mediaSource?.rosetteInput?.quizStatusType) &&
        this.mediaSource?.log?.percent > 80 &&
        this.percentage > 80
      );
    },
    hasRosette() {
      return this.mediaSource?.rosetteInput?.isRosetteTask;
    },
  },
  data() {
    return {
      userGuideRosetteType: 8,
      showRosetteQuizPopup: false,
      rosetteImage: require('@/assets/icons/rosette-icon.svg'),
      quizKey: 0,
      isSurveySuccessful: false,
    };
  },
  methods: {
    click(e, param) {
      this.$emit('handleRoute', e, param);
    },
    openQuizPopup() {
      this.quizKey += 1; // Increment the key to force re-render
      this.showRosetteQuizPopup = true;
    },
    closeQuizPopup() {
      this.showRosetteQuizPopup = false;
    },
    handleSurveyTakenStatus(status) {
      this.isSurveySuccessful = status === SurveyTakenStatusType.Successful;
    },
  },
};
</script>

<style scoped lang="scss">
.single-list-item {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;

  & > div {
    flex: 1;
  }
}
.media {
  width: 175px;
  height: 100px;
}
.single-list-top {
  img {
    width: 100%;
  }
}
.single-list-bottom {
  background-color: palette-color-level('white', '100');
  padding: palette-space-level(20);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  .single-item-text {
    line-height: 1.33;
    min-height: palette-space-level(50);
  }

  .progressbar-wrapper {
    display: flex;
    align-items: center;
    padding-top: palette-space-level(30);

    .progressbar {
      position: relative;
      width: 225px;
      height: 6px;
      background-color: #dddddd;
      border-radius: palette-radius-level('8');
      margin-right: palette-space-level('10');

      .completed-part {
        position: absolute;
        height: 100%;
        top: 0;
        left: 0;
        background: green;
        border-radius: palette-radius-level('8');
      }
    }
  }
}

.content-action-btn {
  margin-top: palette-space-level('20');
}
</style>
